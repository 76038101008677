<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-row class="match-height">
      <b-col md="12">
        <b-card>
          <div class="mb-1 d-flex justify-content-between align-items-center flex-wrap">
            <h4>{{ $t('sites.sites') }}</h4>
            <div class="flex">
              <b-button
                v-if="sites.length"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('sites.siteadd')"
                variant="primary"
                class="btn-icon mb-1"
                :to="{ path: `/users/${$route.params.userId}/sites/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </b-button>
              <b-button
                v-if="sites.length > perPage"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-primary"
                class="btn-icon mb-1 ml-25"
                :to="{ path: `/users/${$route.params.userId}/sites`}"
              >
                {{ $t('sites.allsites') }}
              </b-button>
            </div>

          </div>
          <div
            v-if="sitesLoading"
            class="d-flex justify-content-center mb-1"
          >
            <b-spinner label="Loading..." />
          </div>
          <div
            v-else-if="sites.length"
          >
            <SitesTable
              :sites="sites"
              :page-length.sync="perPage"
              :auth-user-data="authUserData"
              :account="account"
            />
          </div>
          <div v-else-if="sites.length === 0 && !sitesLoading">
            <div
              class="text-center"
            >
              <h5>{{ $t('sites.nosites') }}</h5>
              <p>{{ $t('sites.addsite') }}:</p>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                :to="{ path: `/users/${$route.params.userId}/sites/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                {{ $t('sites.siteadd') }}
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card>
          <div class="mb-1 d-flex justify-content-between align-items-center flex-wrap">
            <h4>{{ $t('Campaigns') }}</h4>
            <div class="flex">
              <b-button
                v-if="campaigns.length"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('campaigns.campaignadd')"
                variant="primary"
                class="btn-icon mb-1"
                :to="{ path: `/users/${$route.params.userId}/campaigns/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </b-button>
              <b-button
                v-if="campaigns.length > perPage"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-primary"
                class="btn-icon mb-1 ml-25"
                :to="{ path: `/users/${$route.params.userId}/campaigns`}"
              >
                {{ $t('campaigns.allcampaigns') }}
              </b-button>
            </div>
          </div>
          <div
            v-if="campaignsLoading"
            class="d-flex justify-content-center mb-1"
          >
            <b-spinner label="Loading..." />
          </div>
          <div
            v-else-if="campaigns.length"
          >
            <CampaignsTable
              :campaigns="campaigns"
              :page-length.sync="perPage"
              :auth-user-data="authUserData"
              :account="account"
              @fetchAccountCampaigns="fetchAccountCampaigns($event)"
            />
          </div>
          <div v-else-if="campaigns.length === 0 && !campaignsLoading">
            <div
              class="text-center"
            >
              <h5>{{ $t('campaigns.nocampaigns') }}</h5>
              <p>{{ $t('campaigns.addcampaign') }}:</p>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                :to="{ path: `/users/${$route.params.userId}/campaigns/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                {{ $t('campaigns.campaignadd') }}
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="gift-modal"
      title="НОВОРІЧНИЙ БОНУС!"
      hide-footer
      centered
      size="lg"
    >
      <router-link :to="{name: 'dashboard-users-payments'}">
        <img
          class="img-fluid"
          :src="giftImg"
        >
      </router-link>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import {
  BSpinner,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import SitesTable from '@/views/ui/tables/SitesTable.vue'
import CampaignsTable from '@/views/ui/tables/CampaignsTable.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    UserToolbar,
    BSpinner,
    Breadcrumbs,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    SitesTable,
    CampaignsTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      giftImg: require('@/assets/images/modal/gift.png'),
      breadcrumbItems: [],
      account: {},
      perPage: 5,
      sitesLoading: true,
      campaignsLoading: true,
      sites: [],
      campaigns: [],
    }
  },
  computed: {
    storeAccount() {
      return this.$store.getters['dashboard-user-toolbar/account'](this.$route.params.userId)
    },
  },
  created() {
    const { userId } = this.$route.params
    this.getStorageAccountData(userId)
    this.fetchAccountSites(userId)
    this.fetchAccountCampaigns(userId)
  },
  mounted() {
    if (localStorage.getItem('bonus')) {
      this.$refs['gift-modal'].show()
      localStorage.removeItem('bonus')
    }
  },
  methods: {
    async getStorageAccountData(userId) {
      await this.$store.dispatch('dashboard-user-toolbar/getAccount', userId)
      this.account = this.storeAccount
      this.setBreabcrumbs()
    },
    async fetchAccountSites(userId) {
      const responseAccountData = await useJwt.accountSites(userId)
      this.sites = responseAccountData.data.sites || []
      this.sitesLoading = false
    },
    async fetchAccountCampaigns(userId) {
      this.campaigns = []
      this.campaignsLoading = true
      const responseData = await useJwt.accountCampaigns(userId)
      this.campaigns = responseData.data.campaigns || []
      this.campaignsLoading = false
    },
    setBreabcrumbs() {
      if (this.account.is_admin) {
        this.breadcrumbItems = [
          {
            text: 'users',
            to: '/users/',
          },
          {
            text: this.account.name,
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: this.account.name,
            active: true,
          },
        ]
      }
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
